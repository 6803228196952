<template>
  <v-dialog
    persistent
    v-model="show"
    max-width="400px"
    content-class="rounded-xl"
  >
    <v-card :outlined="$vuetify.theme.dark" rounded="xl">
      <v-card-title class="d-flex justify-space-between">
        {{ dialogTitle }}
        <v-btn icon @click="onClose">
          <v-icon size="26">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-form class="mt-6" lazy-validation ref="form">
          <v-row no-gutters>
            <v-col cols="12">
              <v-autocomplete
                auto-select-first
                :menu-props="{
                  bottom: true,
                  offsetY: true,
                }"
                append-icon="mdi-chevron-down"
                v-model="form.country"
                :items="getCountryList"
                item-text="name"
                item-value="id"
                flat
                outlined
                label="Select Country"
                dense
              >
              </v-autocomplete
            ></v-col>
            <v-col cols="12">
              <v-text-field
                v-model="form.name"
                label="State Name"
                :rules="[$rules.required]"
                height="42px"
                type="text"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          :loading="btnLoading"
          class="text-capitalize px-8 my-2"
          height="40px"
          color="primary"
          @click="submit"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      btnLoading: false,
      form: {
        country: null,
        name: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      dialog: "stateManagement/dialog",
      getCountryList: "getCountryList",
    }),
    show: {
      get() {
        return this.dialog.show;
      },
      set(value) {
        this.toggleDialog({ show: value });
      },
    },
    dialogTitle() {
      if (this.dialog.type === "add") return "Add State";
      if (this.dialog.type === "edit") return "Edit State";
      return "";
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.onOpen();
      } else this.onClose();
    },
  },
  methods: {
    ...mapActions({
      toggleDialog: "stateManagement/toggleDialog",
      showSnackbar: "snackBar/showSnackbar",
    }),

    onOpen() {
      if (this.dialog.country) {
        this.form.country = this.dialog.country;
      }
    },

    onClose() {
      this.$refs.form.reset();
      this.form = {
        country: null,
        name: "",
      };
      this.toggleDialog({ show: false, type: null, country: null });
    },

    submit() {
        if (this.$refs.form.validate()) {
            this.$btnLoading = true

            const data = {
                ...this.form,
                is_active: true
            }

            const successHandler = (res) => {
                this.showSnackbar({
                    text: res.data.message,
                    color: "success",
                });
                this.onClose()
                this.$emit('on-submit', res.data.data)
            }
            
            this.$request(this.$keys.POST, this.$urls.master.state.create, {
                data: data,
                onSuccess: successHandler
            })
        }
    }
  },
};
</script>